import axios from 'axios';

const BASE_URL = 'http://ec2-54-85-133-58.compute-1.amazonaws.com:3000/'; //production

/**
 * Performs a GET request to the specified URL with optional headers.
 * @param {string} url - The URL to send the GET request to.
 * @param {Object} header - Optional headers to include in the request.
 * @returns {Promise} - A promise that resolves with the response data from the GET request.
 */
export const get = async (url, header) => {

    return await axios.get(BASE_URL + url, { headers: { ...header } });
};

/**
 * Performs a POST request to the specified URL with optional headers and request data.
 * @param {string} url - The URL to send the POST request to.
 * @param {Object} header - Optional headers to include in the request.
 * @param {Object} requestData - The data to be sent in the POST request body.
 * @returns {Promise} - A promise that resolves with the response data from the POST request.
 * @throws {Error} - Throws an error if the request fails.
 */
export const post = async (url, header, requestData) => {
    return await axios.post(BASE_URL + url, requestData, { headers: { ...header } }).catch(error => {
        throw error;
    });
};


/**
 * Performs a PUT request to the specified URL with optional headers and request data.
 * @param {string} url - The URL to send the PUT request to.
 * @param {Object} header - Optional headers to include in the request.
 * @param {Object} requestData - The data to be sent in the PUT request body.
 * @returns {Promise} - A promise that resolves with the response data from the PUT request.
 * @throws {Error} - Throws an error if the request fails.
 */
export const put = async (url, header, requestData) => {
    return await axios.put(BASE_URL + url, requestData, { headers: { ...header } }).catch(error => {
        throw error;
    });
};

/**
 * Performs a DELETE request to the specified URL with optional headers.
 * @param {string} url - The URL to send the DELETE request to.
 * @param {Object} header - Optional headers to include in the request.
 * @returns {Promise} - A promise that resolves with the response data from the DELETE request.
 * @throws {Error} - Throws an error if the request fails.
 */
export const del = async (url, header) => {
    return await axios.delete(BASE_URL + url, { headers: { ...header } }).catch(error => {
        throw error;
    });
};

