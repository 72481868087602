import {
    get,
} from '../scriptExecutionRequest';

/**
 * Executes an AEC script by sending a GET request to the specified API endpoint.
 * @returns {Promise} - A promise that resolves with the API response data.
 */
export const executeAecScript = async () => {
    const apiResponse = await get('aec/execution').then(res => {
        return res;
    });

    return apiResponse;
}

/**
 * Executes a perpetual script by sending a GET request to the specified API endpoint.
 * @returns {Promise} - A promise that resolves with the API response data.
 */
export const executePerpetualScript = async () => {
    const apiResponse = await get('perpetual/execution').then(res => {
        return res;
    });

    return apiResponse;
}