import {
    get as getBimHubs
} from '../apiRequest';

/**
 * Retrieves all BIM hubs by sending a GET request to the specified API endpoint.
 * @returns {Promise} - A promise that resolves with the API response data.
 */
export const getAllBimHubs = async () => {
    const apiResponse = await getBimHubs('api/bimHub').then(res => {
        return res;
    });

    return apiResponse;
}