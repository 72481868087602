<template>
    <div>
        <b-overlay :show="isLoading" spinner-variant="warning" rounded="sm" opacity="0.99">
            <b-card class="mt-5" header="Consulta">
                <b-form>

                    <b-form-group id="company-name-label">
                        <b-form-input id="company-name"
                                      type="email"
                                      placeholder="Digite o nome da empresa"
                                      required></b-form-input>
                    </b-form-group>
                </b-form>
                <b-table bordered :fields="fields" striped hover :items="companies">
                    <template #cell(actions)="row">
                        <div class="d-flex justify-content-center">
                            <font-awesome-icon class="fa-xl mr-2" @click="editCompany(row.item.id)" icon="fa-solid fa-pen-to-square" />
                            <font-awesome-icon class="fa-xl" @click="openDeleteModal(row.item.id)" icon="fa-solid fa-trash-can " />
                        </div>
                    </template>
                </b-table>
            </b-card>
        </b-overlay>

        <!--Inactive modal-->
        <div>
            <b-modal hide-backdrop ref="modal-inactive" id="modal-inactive" title="Excluir empresa" hide-footer static>
                <p class="my-4">Deseja mesmo excluir a empresa selecionada?</p>
                <div class="d-flex justify-content-between">
                    <b-button @click="$bvModal.hide('modal-inactive')" variant="outline-secondary" class="mt-3">Cancelar</b-button>
                    <b-button variant="danger" @click="deleteCompany(companyIdToDisable)" class="mt-3">Confirmar</b-button>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
    import { deleteCompany as deleteCompanyService } from "../../../../../services/companyService";

    export default {
        data() {
            return {
                fields: [
                    { key: 'description', label: 'Empresa', sortable: true },
                    { key: 'cnpj', label: 'CNPJ', sortable: true },
                    { key: 'bimLicenses', label: 'Total de licenças Autodesk Docs', sortable: true },
                    { key: 'aecLicenses', label: 'Total de licenças AEC Collection', sortable: true },
                    { key: 'perpetualLicenses', label: 'Total de licenças Perpétuas', sortable: true },
                    { key: 'buildLicenses', label: 'Total de licenças Autodesk Docs build', sortable: true },
                    { key: 'actions', label: 'Editar', class: 'text-center' }
                ],
                isLoading: true,
                companyIdToDisable: '',

            }
        },
        methods: {
            /**
             * Disables loading by setting isLoading flag to false.
             */
            disableLoading() {
                this.isLoading = false;
            },
            /**
             * Opens a modal for deleting a company.
             * @param {number} id - The ID of the company to delete.
             */
            openDeleteModal(id) {
                this.companyIdToDisable = id;

                this.$refs['modal-inactive'].show();
            },
            /**
             * Deletes a company.
             * @param {number} id - The ID of the company to delete.
             */
            async deleteCompany(id) {
                this.$refs['modal-inactive'].hide();
                this.isLoading = true;

                await deleteCompanyService(id).then(() => {
                    this.$emit("callSuccessMessage", "Empresa excluída com sucesso", this.disableLoading);
                }).catch(() => {
                    this.$emit("callErrorMessage", "Erro na hora de excluir a empresa, contate o administrador do sistema", this.disableLoading);
                });
            },
            /**
             * Retrieves company information for editing.
             * @param {number} id - The ID of the company to edit.
             */
            async editCompany(id) {
                const company = await this.companies.filter(u => u.id === id)[0];

                this.$emit("setCompanyToEdit", company);
            },
        },
        props: ['companies'],
        created() {
            this.$emit("getCompanies", this.disableLoading);
        }
    }
</script>

<style>
    .fa-pen-to-square {
        color: green;
        cursor: pointer;
    }

    .fa-trash-can {
        color: red;
        cursor: pointer;
    }
</style>