<template>
  <div>
    <b-overlay
      spinner-variant="warning"
      rounded="sm"
      opacity="0.99"
      :show="isLoading"
    >
      <b-card class="mt-5" header="Execução script">
        <div class="row">
          <div class="col-md-3">
            <b-button @click="executePerpetualLicensesScript" variant="success"
              >Gerar Licenças Perpétuas</b-button
            >
          </div>
          <div class="col-md-3">
            <b-button @click="executeAecLicensesScript" variant="success"
              >Gerar AEC Collection</b-button
            >
          </div>
          <div class="col-md-4"></div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  executePerpetualScript,
  executeAecScript,
} from "../../../../../services/scriptExecutionService";
import { getAllBimHubs } from "../../../../../services/bimHubService";
import Vue from "vue";
import jwt_decode from "jwt-decode";

export default {
  data() {
    return {
      isLoading: false,
      company: "",
    };
  },
  methods: {
    /**
     * Disables loading by setting isLoading flag to false.
     */
    disableLoading() {
      this.isLoading = false;
    },
    /**
     * Fetches the company data and sets the company description based on the user's company ID.
     */
    async getCompany() {
      const apiBimHubs = await getAllBimHubs().then((res) => {
        return res.data;
      });

      const parsedToken = jwt_decode(Vue.$keycloak.token);
      var companyId = parsedToken.company_id;

      await apiBimHubs.map((bc) => {
        if (bc.companyId === companyId) {
          this.company = bc.description;
        }
      });
    },
    /**
     * Executes the perpetual licenses script, displays success or error messages based on the result.
     */
    async executePerpetualLicensesScript() {
      this.isLoading = true;
      this.getCompany();

      executePerpetualScript()
        .then(() => {
          this.$emit(
            "callSuccessMessage",
            "Script de licenças perpétuas executado com sucesso para " +
              this.company,
            this.disableLoading
          );
        })
        .catch(() => {
          this.$emit(
            "callErrorMessage",
            "Erro ao executar o script de licenças perpétuas para " +
              this.company,
            this.disableLoading
          );
        });
    },
    /**
     * Executes the AEC licenses script, displays success or error messages based on the result.
     */
    async executeAecLicensesScript() {
      this.isLoading = true;
      this.getCompany();

      executeAecScript()
        .then(() => {
          this.$emit(
            "callSuccessMessage",
            "Script de AEC Collection executado com sucesso para " +
              this.company,
            this.disableLoading
          );
        })
        .catch(() => {
          this.$emit(
            "callErrorMessage",
            "Erro ao executar o script de AEC Collection para " + this.company,
            this.disableLoading
          );
        });
    },
  },
};
</script>