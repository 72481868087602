<template>
    <div style="margin: 1em">
        <b-alert variant="danger" dismissible fade :show="showErrorMessage" @dismissed="showErrorMessage=false">{{errorMessage}}</b-alert>
        <b-alert variant="success" dismissible fade :show="showSuccessMessage" @dismissed="showSuccessMessage=false">{{successMessage}}</b-alert>

        <div>
            <company-form @callErrorMessage="callErrorMessage" @callSuccessMessage="callSuccessMessage" @setCompanyToEdit="setCompanyToEdit" @resetUserFields="resetUserFields" :company="company" @onSelectCompany="onSelectCompany" />
        </div>
        <div>
            <script-execution @callErrorMessage="callErrorMessage" @callSuccessMessage="callSuccessMessage"/>
        </div>
        <div>
            <company-grid @callErrorMessage="callErrorMessage" @callSuccessMessage="callSuccessMessage" @setCompanyToEdit="setCompanyToEdit" @getCompanies="getCompanies" :companies="companies" />
        </div>
    </div>
</template>

<script>
    import Form from '../components/form'
    import Grid from '../components/grid'
    import ScriptExecution from '../components/scriptExecution'
    import { getCompanies as getCompaniesService } from '../../../../services/companyService'
    export default {
        data() {
            return {
                showErrorMessage: false,
                showSuccessMessage: false,
                errorMessage: "",
                successMessage: "",
                company: {},
                companies: []
            }
        },
        components: {
            'company-form': Form,
            'company-grid': Grid,
            'script-execution': ScriptExecution
        },
        methods: {
            /**
             * Displays an error message and optionally executes callback functions after a specified delay.
             * @param {string} message - The error message to be displayed.
             * @param {...Function} callback - Optional callback functions to be executed after displaying the error message.
             */
            callErrorMessage(message, ...callback) {
                var vm = this;

                vm.errorMessage = message;
                vm.showErrorMessage = true;

                setTimeout(() => {
                    vm.showErrorMessage = false;
                }, 5000);

                if (callback)
                    callback.forEach(c => c.apply());
            },
            /**
            * Asynchronous function to display a success message for a certain period and execute callbacks if provided.
            * @param {string} message - The success message to be displayed.
            * @param {...function} callback - The callbacks to be executed after displaying the success message.
            */
            async callSuccessMessage(message, ...callback) {
                await this.getCompanies().then(() => {
                    var vm = this;

                    vm.successMessage = message;
                    vm.showSuccessMessage = true;

                    setTimeout(() => {
                        vm.showSuccessMessage = false;
                    }, 5000);

                    if (callback)
                        callback.forEach(c => c.apply());
                });
            },
            /**
             * Resets the user fields to their default values.
             * @param {Function} callback - An optional callback function to be executed after resetting the user fields.
             */
            resetUserFields(...callback) {
                this.company = {};

                if (callback)
                    callback.forEach(c => c.apply());
            },
            /**
             * Sets the company object for editing and optionally executes callback functions.
             * @param {object} company - The company object to set for editing.
             * @param {...Function} callback - Optional callback functions to execute after setting the company object.
             */
            setCompanyToEdit(company, ...callback) {
                this.company = { ...company };

                if (callback)
                    callback.forEach(c => c.apply());
            },            
            /**
             * Updates the company object with selected company properties.
             * @param {object} company - The selected company object.
             */
            onSelectCompany(company) {
                this.company = { ...this.company, ...company };
            },
            /**
             * Fetches companies from the service and optionally executes callback functions after fetching.
             * @param {...Function} callback - Optional callback functions to execute after fetching companies.
             */
            async getCompanies(...callback) {
                const companies = await getCompaniesService().then(result => {
                    return result.data;
                });

                this.companies = companies;

                if (callback)
                    callback.forEach(c => c.apply());
            }
        },
        created() { }
    }
</script>

<style>
    .card-header {
        background-color: black;
        color: white;
    }
</style>