<template>
  <div>
    <!-- Overlay to show loading spinner -->
    <b-overlay
      :show="isLoading"
      spinner-variant="warning"
      rounded="sm"
      opacity="0.99"
    >
      <!-- Card for company registration -->
      <b-card class="mt-3" header="Cadastro">
        <!-- Form for company registration -->
        <b-form @submit.prevent="saveCompany" v-if="show">
          <!-- Company Name -->
          <b-form-group
            id="input-group-1"
            label="Empresa:"
            label-for="company-name"
          >
            <b-form-select
              id="company-name"
              v-model="company.companyId"
              :state="validateState('companyId')"
              aria-describedby="input-1-live-feedback"
              :options="bimHubs"
              @change="onSelectCompanyChange"
            >
              <!-- Default option -->
              <template #first>
                <b-form-select-option :value="undefined" disabled
                  >Selecione...</b-form-select-option
                >
              </template>
            </b-form-select>
            <!-- Validation error feedback -->
            <b-form-invalid-feedback id="input-1-live-feedback"
              >Campo obrigatório</b-form-invalid-feedback
            >
          </b-form-group>

          <!-- Company CNPJ -->
          <b-form-row>
            <b-col>
              <b-form-group
                id="company-cnpj-label"
                label="Cnpj:"
                label-for="company-cnpj"
              >
                <b-form-input
                  class="w-100"
                  id="company-cnpj"
                  v-model="company.cnpj"
                  type="text"
                  placeholder="Insira o CNPJ (Opcional)"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <!-- Total Autodesk Docs Licenses -->
            <b-col>
              <b-form-group
                id="company-bim-licenses-label"
                label="Total de licenças Autodesk Docs:"
                label-for="company-bim-licenses"
              >
                <b-form-input
                  class="w-100"
                  min="0"
                  id="company-bim-licenses"
                  v-model="company.bimLicenses"
                  type="number"
                  placeholder="Insira o total de licenças Autodesk Docs (Opcional)"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <!-- Total AEC Collection Licenses -->
            <b-col>
              <b-form-group
                id="company-aec-licenses-label"
                label="Total de licenças AEC Collection:"
                label-for="company-aec-licenses"
              >
                <b-form-input
                  class="w-100"
                  min="0"
                  id="company-aec-licenses"
                  v-model="company.aecLicenses"
                  type="number"
                  placeholder="Insira o total de licenças AEC Collection (Opcional)"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <!-- Total IDS Perpetual Licenses -->
            <b-col>
              <b-form-group
                id="company-perpetual-licenses-label"
                label="Total de licenças IDS - Perpétua:"
                label-for="company-perpetual-licenses"
              >
                <b-form-input
                  class="w-100"
                  min="0"
                  id="company-perpetual-licenses"
                  v-model="company.perpetualLicenses"
                  type="number"
                  placeholder="Insira o total de licenças IDS Perpétuas (Opcional)"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <!-- Total Autodesk Docs Build Licenses -->
          <b-form-row>
            <b-col>
              <b-form-group
                id="company-build-licenses-label"
                label="Total de Licenças Autodesk Docs Build:"
                label-for="company-build-licenses"
              >
                <b-form-input
                  class="w-100"
                  min="0"
                  id="company-build-licenses"
                  v-model="company.buildLicenses"
                  type="number"
                  placeholder="Insira o total de licenças Build (Opcional)"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col />
            <b-col />
            <b-col />
          </b-form-row>

          <!-- Edit user mode-->
          <div class="d-flex justify-content-end">
            <b-button v-if="company.id" class="mr-3" @click="cancelEdit"
              >Cancelar</b-button
            >
            <b-button v-if="company.id" variant="success" type="submit"
              >Editar</b-button
            >
          </div>

          <!-- Save new user mode-->
          <b-button
            v-if="!company.id"
            class="float-right"
            variant="success"
            type="submit"
            >Salvar</b-button
          >
        </b-form>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { getAllBimHubs } from "../../../../../services/bimHubService";
import { saveCompany as saveCompanyService } from "../../../../../services/companyService";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      bimHubs: [],
      show: true,
      isLoading: false,
    };
  },
  props: ["company"],
  validations() {
    return {
      company: {
        companyId: { required },
      },
    };
  },
  methods: {
    /**
     * Validates the state of a form field.
     * @param {string} name - The name of the form field to validate.
     * @returns {boolean|null} - Returns true if the field is dirty and has no errors, null otherwise.
     */
    validateState(name) {
      const { $dirty, $error } = this.$v.company[name];
      return $dirty ? !$error : null;
    },
    /**
     * Disables the loading state.
     */
    disableLoading() {
      this.isLoading = false;
    },    
    /**
     * Resets the form fields.
     */
    resetFields() {
      this.$emit("resetUserFields");
    },
    /**
     * Cancels the editing process by resetting the form and emitting an event to clear the edited company.
     */
    cancelEdit() {
      this.$v.$reset();
      this.$emit("setCompanyToEdit", {});
    },
    /**
     * Saves the company details by validating the form, then sending a request to save the company.
     * Handles success and error cases by emitting appropriate messages and resetting the form.
     */
    async saveCompany() {
      this.$v.company.$touch();
      if (this.$v.company.$anyError) return;

      this.isLoading = true;
      this.$v.$reset();

      await saveCompanyService(this.company)
        .then(() => {
          this.$emit(
            "callSuccessMessage",
            "Cadastro de empresa atualizado",
            this.disableLoading,
            this.resetFields
          );
        })
        .catch((error) => {
          if (error.response.status == 409) {
            this.$emit(
              "callErrorMessage",
              "Empresa ja cadastrada",
              this.disableLoading
            );
          } else {
            this.$emit(
              "callErrorMessage",
              "Erro cadastro de empresa, contate o administrador do sistema",
              this.disableLoading
            );
          }
        });
    },    
    /**
     * Handles the change event when a company is selected.
     * Emits an event with the selected company's details.
     * @param {number} event - The ID of the selected company.
     */
    async onSelectCompanyChange(event) {
      const selectedCompanyDescription = await this.bimHubs.filter(
        (bc) => bc.value === this.company.companyId
      )[0].text;

      const company = {
        companyId: event,
        description: selectedCompanyDescription,
      };

      this.$emit("onSelectCompany", company);
    },    
    /**
     * Fetches all BIM hubs.
     * @returns {Array} - An array of objects containing BIM hub descriptions and IDs.
     */
    async getBimHubs() {
      const apiBimHubs = await getAllBimHubs().then((res) => {
        return res.data;
      });

      return apiBimHubs.map((bc) => {
        return {
          text: bc.description,
          value: bc.companyId,
        };
      });
    },
  },
  created() {
    this.getBimHubs().then((result) => {
      this.bimHubs.push(...result);
    });
  },
};
</script>